class ProjectsServiceClass {
    public socket: any;
    private namespace: string;
    constructor(_socket, _namespace) {
        this.socket = _socket;
        this.namespace = _namespace;
    }

    // get clicked page
    getProjectsPageService(query: string, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                this.socket.emit("GET_PROJECTS_PAGE", query, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }


    GetprojectJson(id) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GETPROJECTJSON', id, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    exportProject(id) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('EXPORTPROJECT', id, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    ImportprojectJson(jsonObj) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('IMPORTPROJECTJSON', jsonObj, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    getAllProjects(query: string, top: number | null, skip: number | null, orderby: string | null, reverse: boolean | null, collectionname: string | null) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GET_ALL_PROJECTS', query, top, skip, orderby, reverse, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    GetProjectsGroups(query:String,top: number, skip: number, orderby: string, reverse: boolean) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GETPROJECTSGROUPS',query, top, skip, orderby, reverse, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    GetProjectsBackups(ProjectId: any) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('GETPROJECTSBACKUPS', ProjectId, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    SetBackupAsCurrent(ProjectId: any, BackupId: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('SETBACKUPASCURRENT', ProjectId, BackupId, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    deleteBackupProject(ProjectId: any) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('DELETE_BACKUP_PROJECTS', ProjectId, function (err) {
                    if (err) { return reject(err); }
                    resolve("");
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    UpdateProjectsRoles(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATEPROJECTSROLES', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }



}